import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import {
  formatGender,
  formatNationality,
  formatIDTypes,
  formatQualification,
  formatEmploymentStatus,
  formatCity,
  formatResidence,
  formatAge
} from "../../utils/formatCandidate";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


const DownloadPreviousComponent = () => {

  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  const api = useAxios()

  const headers = [
    { label: "#", key: "serial_no" },
    { label: "اسم الدورة", key: "course_name" },
    { label: "تاريخ البدء", key: "start_date" },
    { label: "تاريخ الانتهاء", key: "end_date" },
    { label: "الإسم الكامل باللغة العربية", key: "candidate.ar_name" },
    { label: "الإسم الكامل باللغة الانجليزية", key: "candidate.en_name" },
    { label: "البريد الإلكتروني", key: "candidate.email" },
    { label: "رقم الجوال", key: "candidate.phone" },
    { label: "الجنس", key: "candidate.gender" },
    { label: "العمر", key: "candidate.age" },
    { label: "المؤهل العلمي", key: "candidate.qualification" },
    { label: "الحالة الوظيفية", key: "candidate.employment_status" },
    { label: "مسمى وظيفي", key: "candidate.job_title" },
    { label: "جهة العمل", key: "candidate.organization" },
    { label: "عدد سنوات الخبرة", key: "candidate.years_of_experience" },
    { label: "نوع الوثيقة", key: "candidate.id_type" },
    { label: "رقم الهوية", key: "candidate.id_no" },
    { label: "الجنسية", key: "candidate.nationality" },
    { label: "مكان الإقامة", key: "candidate.residence" },
    { label: "المدينة", key: "candidate.city" },
    { label: 'Pre Assessment %', key: "candidate.pre_assessment_percentage" },
    { label: 'Post Assessment %', key: "candidate.post_assessment_percentage" },
    { label: "تم إرسال التعليقات", key: "candidate.feedback" },
    { label: "إظهار الشهادة", key: "candidate.show_certificate" },
    { label: "نسبة الحضور", key: "candidate.attendance_percentage" },
  ];

  const exportToXLSX = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data, { header: headers.map(h => h.key) });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Adding custom headers
    XLSX.utils.sheet_add_aoa(worksheet, [headers.map(h => h.label)], { origin: 'A1' });

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    setProgress(0);

    const data = await fetchData();
    const formattedData = formatData(data);
    exportToXLSX(formattedData, 'Previous_Courses_Report');

    setIsDownloading(false);
    setProgress(100);
  };

  const fetchData = async () => {
    let allData = [];
    let currentPage = 1;
    let totalPages = 1;

    try {
      // Fetch the first page to get total_pages
      const response = await api.get(`/courses/previous-courses-report?size=500&page=${currentPage}`);
      const { registrations, total_pages } = response.data;
      allData = registrations;
      totalPages = total_pages;

      // Update progress
      setProgress(Math.round((currentPage / totalPages) * 100));

      // Fetch remaining pages
      while (currentPage < totalPages) {
        currentPage += 1;
        const pageResponse = await api.get(`/courses/previous-courses-report?size=500&page=${currentPage}`);
        allData = [...allData, ...pageResponse.data.registrations];

        // Update progress
        setProgress(Math.round((currentPage / totalPages) * 100));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    return allData;
  };

  const formatData = (registrations) => {
    return registrations.map((registration, index) => ({
      serial_no: index + 1,
      course_name: registration?.course_name,
      start_date: registration?.start_date,
      end_date: registration?.end_date,
      course_name: registration?.course_name,
      "candidate.ar_name": registration?.candidate?.ar_name,
      "candidate.en_name": registration?.candidate?.en_name,
      "candidate.email": registration?.candidate?.email,
      "candidate.phone": registration?.candidate?.phone,
      "candidate.gender": formatGender(registration?.candidate?.gender),
      "candidate.age": formatAge(registration?.candidate?.age),
      "candidate.qualification": formatQualification(registration?.candidate?.qualification),
      "candidate.employment_status": formatEmploymentStatus(registration?.candidate?.employment_status),
      "candidate.job_title": registration?.candidate?.job_title ?? "-",
      "candidate.organization": registration?.candidate?.organization ?? "-",
      "candidate.years_of_experience": registration?.candidate?.years_of_experience ?? "-",
      "candidate.id_type": formatIDTypes(registration?.candidate?.id_type),
      "candidate.id_no": registration?.candidate?.id_no,
      "candidate.nationality": formatNationality(registration?.candidate?.nationality),
      "candidate.residence": formatResidence(registration?.candidate?.residence),
      "candidate.city": formatCity(registration?.candidate?.city),
      "candidate.pre_assessment_percentage": registration?.pre_assessment_percentage ?? "-",
      "candidate.post_assessment_percentage": registration?.post_assessment_percentage ?? "-",
      "candidate.feedback": registration?.feedback?.submitted ? "نعم" : "لا",
      "candidate.show_certificate": registration?.show_certificate ? "نعم" : "لا",
      "candidate.attendance_percentage": registration?.attendance_percentage,
    }));
  };


  return (
    <div className="data-download-btn">
      <Button
        color="primary"
        style={{ marginBottom: '10px', backgroundColor: '#8E46CD' }}
        startIcon={<FileDownloadIcon />}
        variant="contained"
        onClick={handleDownload}
        disabled={isDownloading}
      >
        {isDownloading ? 'Please Wait...' : 'Download Previous Courses'}
      </Button>
      
      {isDownloading && (
        <div className={`fade-in ${isDownloading ? 'visible' : ''}`} style={{ width: '100%', mt: 2 }}>
          <LinearProgressWithLabel className="progress-bar" value={progress} />
          <p>قد يتطلب التنزيل بضع دقائق</p>
        </div>
      )}
    </div>
  )

}

export default DownloadPreviousComponent;


