import * as yup from "yup";

const MAX_FILE_SIZE = 2000000;

const validFileExtensions = { image: ["jpg", "png", "jpeg"] };

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

export const adminLoginSchema = yup.object().shape({
  email: yup.string().email("أدخل بريد إلكتروني متاح").required("مطلوب"),
  password: yup.string().required("مطلوب"),
});

export const courseSchema = yup.object().shape({
  name: yup.string().required("مطلوب"),
  description: yup.string(),
  title_img: yup.mixed().required("مطلوب"),
});

export const courseCategorySchema = yup.object().shape({
  name: yup.string().required("مطلوب"),
});

export const locationSchema = yup.object().shape({
  name: yup.string().required("مطلوب"),
});
export const notificationsSchema = yup.object().shape({
  title: yup.string().required("مطلوب"),
  description: yup.string().required("مطلوب"),
});
export const attendanceSchema = yup.object().shape({
  course: yup.string().required("مطلوب"),
  dayNumber: yup.string().required("مطلوب"),
});

export const trainerSchema = yup.object().shape({
  name: yup.string().required("مطلوب"),
  email: yup.string().email("Eter valid enmail").required("مطلوب"),
  description: yup.string(),
});

export const evaluationSchema = yup.object().shape({
  course: yup.string().required("مطلوب"),
});

// const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] [APap][Mm]$/; // AM/PM time format regex

export const scheduleSchema = yup.object().shape({
  course: yup.number().required("مطلوب"),
  location: yup.string().required("مطلوب"),
  trainer: yup.string(),
  // trainer: yup.string().required("مطلوب"),
  // start_time: yup.string().required("Start Time is required"),
  // end_time: yup.string().required("مطلوب"),

  language: yup.string().required("مطلوب"),
  // start_date: yup.date().required("مطلوب"),
  // end_date: yup.date().required("مطلوب"),
  duration: yup.number().required("مطلوب"),
  hours: yup.number().required("مطلوب"),
  start_date: yup.date().required("مطلوب"),
  end_date: yup
    .date()
    .required("مطلوب")
    .min(
      yup.ref("start_date"),
      "يجب أن يكون تاريخ الانتهاء مساويًا لتاريخ البدء أو متأخرًا عنه"
    ),
  start_time: yup.string().required("مطلوب"),

  end_time: yup
    .string()
    .required("مطلوب"),
});

export const questionSchema = yup.object().shape({
  question: yup.string().required("مطلوب"),
  options1: yup.string().required("مطلوب"),
  options2: yup.string().required("مطلوب"),
  options3: yup.string().required("مطلوب"),
  options4: yup.string().required("مطلوب"),
  correctOption: yup.boolean().required("مطلوب"),
});
export const editQuestionSchema = yup.object().shape({
  statement: yup.string().required("مطلوب"),
  option1: yup.string().required("مطلوب"),
  option2: yup.string().required("مطلوب"),
  option3: yup.string().required("مطلوب"),
  option4: yup.string().required("مطلوب"),
  correct_option: yup.string().required("مطلوب"),
});
